"use client";

import { UpOutlined } from "@ant-design/icons";
import { Button, Dropdown } from "antd";
import classNames from "classnames";
import Link from "next/link";
import styles from "./index.module.scss";

export interface IShowPosition {
	fixed?: boolean;
	block?: boolean;
}
const Footer: React.FC<IShowPosition> = ({ fixed, block }) => {
	return (
		<div
			className={classNames(styles["footer-container"], {
				[styles["show"]]: fixed,
				[styles["show-block"]]: block,
			})}
		>
			<div className={classNames(styles["footer"])}>
				<Dropdown
					menu={{
						items: [
							{
								key: "1",
								label: (
									<a href="https://x.com/IItopk97792" target="_blank">
										Twitter
									</a>
								),
								onClick: () => {},
							},
							{
								key: "2",
								label: (
									<a href="https://www.youtube.com/@itopick" target="_blank">
										YouTube
									</a>
								),
								onClick: () => {},
							},
						],
					}}
					placement="bottomLeft"
				>
					<Button size="small" type="link">
						Follow Us <UpOutlined />
					</Button>
				</Dropdown>

				<Dropdown
					menu={{
						items: [
							{
								key: "1",
								label: <a href="/static/aboutus">About Us</a>,
								onClick: () => {},
							},
							{
								key: "2",
								label: <a href={`mailto:help@itopick.com`}>Contact Us</a>,
								onClick: () => {},
							},
						],
					}}
					placement="bottomLeft"
				>
					<Button size="small" type="link">
						About <UpOutlined />
					</Button>
				</Dropdown>
				<Link href="/blog">Blog</Link>
				<Link href="/static/privcacypolicy">Privacy Policy</Link>
				<Link href="/static/terms">Terms</Link>
				<Link href="/static/disclaimer">Disclaimer</Link>
				<Link href="/static/copyright">Copyright Notice</Link>
				<Link href="/static/useragreement">User Agreement</Link>
				{/* <div id="footerCloseBtn" className="icon-btn icon-close"></div> */}
			</div>
		</div>
	);
};

export default Footer;
