export function addSearchParamsToUrl(url: string, params: any) {
	const urlObj = new URL(url, window.location.origin);

	Object.keys(params).forEach((key) => {
		urlObj.searchParams.append(key, params[key]);
	});

	return urlObj.toString();
}

export function replaceSpecialCharacters(str: string) {
	return str.replace(/[^a-zA-Z0-9]/g, "-");
}

export function extractDiscount(
	product_original_price: string,
	product_price: number
) {
	let original_price = 0;
	let discount_percent = 0;

	if (/\$[\d,.]+/.test(product_original_price)) {
		let match = product_original_price.match(/\$([\d,.]+)/);
		if (match) {
			let originalPrice = parseFloat(match[1].replace(/,/g, "")); // 处理千位符
			if (!isNaN(originalPrice)) {
				original_price = originalPrice;
				discount_percent =
					((originalPrice - product_price) / originalPrice) * 100;
			}
		}
	} else if (/\d+%/.test(product_original_price)) {
		let match = product_original_price.match(/(\d+)%/);
		if (match) {
			discount_percent = parseFloat(match[1]);
			if (!isNaN(discount_percent)) {
				original_price = product_price / (1 - discount_percent / 100);
			}
		}
	}

	return {
		original_price: original_price.toFixed(2),
		discount: discount_percent,
		discount_percent: discount_percent.toFixed(0) + "%",
	};
}
