import { useApp } from "@/models/app";
import { addSearchParamsToUrl, extractDiscount } from "@/utils/stringUtils";
import { sendGAEvent } from "@next/third-parties/google";
import { Button } from "antd";
import classNames from "classnames";
import { map } from "lodash";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { usePostHog } from "posthog-js/react";
import { useMemo } from "react";
import URI from "urijs";
import styles from "./index.module.scss";

export interface IGoodProps {
	item: any;
	priority?: boolean;
	canExpand?: boolean;
}

const GoodCard: React.FC<IGoodProps> = ({ item, priority, canExpand }) => {
	const router = useRouter();
	const Mapp = useApp();
	const posthog = usePostHog();
	const goDetail = () => {
		posthog.capture("get_detail_page_view", {
			asin: item?.original_id,
			item_id: item?.ID,
		});
	};

	const link = useMemo(() => {
		const result = item.product_title
			?.trim()
			.match(/\b\w+\b/g)
			.join("-");
		const url = new URI(`/good/${result}`);
		url.setSearch("original_id", item.original_id);
		url.setSearch("ID", item.ID);
		url.setSearch("ref_platform_id", item.ref_platform_id);
		return url.readable();
	}, []);
	const { discount_percent, original_price, discount } = extractDiscount(
		item.product_original_price,
		item.product_price
	);

	const getDeal = (e: any) => {
		e.preventDefault();
		const url = addSearchParamsToUrl(item?.product_url || "", {
			tag: "itopick-20",
		});
		posthog.capture("get_deal_button_click", {
			asin: item?.original_id,
			item_id: item?.ID,
		});

		sendGAEvent("event", "get_deal_button_click", {
			asin: item?.original_id, // Send the asin id to the data layer
			item_id: item?.ID, // Send the asin id to the data layer
		});
		window.open(url, "_blank");
	};
	return (
		<div className={styles["panel"]}>
			{!!item.redTip?.content && (
				<div className={styles["red-tip"]}>{item.redTip?.content}</div>
			)}
			<div className={styles["panel-mobile-left"]}>
				<Link href={link} onClick={goDetail} className={styles["pic-wrap"]}>
					{!!discount && (
						<div className={styles["feature-discount"]}>
							<div className={styles["label"]}>Save {discount_percent}</div>
						</div>
					)}
					<Image
						src={item.product_photo}
						fill
						priority={priority}
						style={{ objectFit: "contain" }}
						alt="product photo"
					/>
					<div className={styles["feature-icon"]}>
						<div
							className={classNames({
								"icon-feature-rating":
									item.product_star_rating <= 4.5 &&
									!item.product_original_price,
								"icon-feature-mostPopular": item.product_num_ratings > 100000,
								"icon-feature-mostComments": false,
								"icon-feature-limited": false,
								"icon-feature-hot":
									(item.rank || 0) < 10 || item.product_star_rating > 4.5,
								"icon-feature-gifts": false,
								"icon-feature-bigCut": !!item.product_original_price,
							})}
						></div>
						<p className={styles["feature-detail"]}>{item.sales_volume}</p>
					</div>
				</Link>
				<div className={styles["action-wrap"]}>
					<Button type="primary" onClick={getDeal} block>
						Buy this item
					</Button>
				</div>
			</div>
			<div className={styles["panel-mobile-right"]}>
				<Link href={link} onClick={goDetail} className={styles["goods-title"]}>
					{item.product_title}
				</Link>
				<div className={styles["goods-desc"]}>
					{map(item.about_product, (item) => (
						<p key={item}>{item}</p>
					))}
				</div>

				<div className={styles["bottom-bar"]}>
					<Link href={link} onClick={goDetail} className={styles["price-wrap"]}>
						<div className="icon-doller"></div>
						<p className={styles["discounted-price"]}>
							{item.product_price?.toFixed(1)}
						</p>
						<p className={styles["original-price"]}>${original_price}</p>
					</Link>
					<div className={styles["operation-box"]}>
						<Link
							href={link}
							onClick={goDetail}
							className={styles["tool-wrap"]}
						>
							<div
								className={classNames(styles["price-trend"], { down: true })}
							></div>
							<div className={styles["goods-btn-list"]}>
								<div className={styles["btn-item"]}>
									{item.likesCount}
									<div
										onClick={() => {}}
										className={classNames(
											styles["icon-btn"],
											styles["icon-noLikeGoods"]
										)}
									></div>
								</div>
								<div className={styles["btn-item"]}>
									{item?.reviewsCount || 0}
									<div
										onClick={() => {}}
										className={classNames(
											styles["icon-btn"],
											styles["icon-shareGoods"]
										)}
									></div>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

export default GoodCard;
